// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    button.btn,a.btn{
        &.marketplace-input-quote-btn{
            flex: none !important;
            margin: 3px 0 !important;

            &.col-sm-12{
                width:100%;
            }
            &.col-sm-6{
                width:calc(50% - 1%);
            }
            &.col-sm-4{
                width:calc(33.3% - 1%);
            }
            &.col-sm-3{
                width:calc(25% - 1%);
            }
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    button.btn,a.btn{
        &.marketplace-input-quote-btn{
            flex: none !important;
            margin: 3px 0 !important;

            &.col-12{
                width:100%;
            }
            &.col-6{
                width:calc(50% - 1%);
            }
            &.col-4{
                width:calc(33.3% - 1%);
            }
            &.col-3{
                width:calc(25% - 1%);
            }
        }
    }

    .view-wrapper{
        padding: 0 !important;
    }

    section div.mat-list div.mat-list-item .mat-list-item-content h5,
    div div.mat-list div.mat-list-item .mat-list-item-content h5{
        height:auto;
    }
}

@media (max-width: 480px) { 
    body .my-5{
        margin-top: 0.5em!important;
    }
    
}



