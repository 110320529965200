body{
    .btn{
        font-size:20px;
    }
}

button.btn,
a.btn{
	font-weight:500;
}

.btn-new{
	text-transform:uppercase;
	border-radius:$btn-border-radius;
	padding: $button-padding;
	box-shadow: $button-shadow;
	font-weight:bold;
	height:$button-height;
	display: flex;
    justify-content: center; 
	align-items: center;
	line-height:1em;
	
	
	&.active{
		background:$first-color !important;
    }
}

a.btn-circle,
button.btn-circle{
	padding: 0;
	width: 70px;
	height: 70px;
	text-align:center;
}

a.btn-circle-medium,
button.btn-circle-medium{
	@extend .btn-circle;
	width: 50px;
	height: 50px;
}

a.btn-circle-small
button.btn-circle-small{
	@extend .btn-circle;
	width: 30px;
	height: 30px;
}

a.btn-first,
button.btn-first{
	@extend .btn-new;
	background: $button-gradient-fallback;
	background: $button-gradient;
	color:#fff;
	border:0;

	i{
		color:#fff;
	}

	&.btn.btn-first.saveThisPlanButton {
		height: auto;
		padding: 7px;
		//background: #919191;
		//background: #c3c3c3;
		//background:#90EE90;
		background:#82b0fe;
	}
}

a.btn-blue,button.btn-blue{
	@extend .btn-new;
	background:$first-color;
	color:#fff;
	border:0;
	i{
		color:#fff;
	}
}

a.btn-first-medium,
button.btn-first-medium{
	@extend a, .btn-first;
	max-width: 200px;
	// padding: 10px 25px;
	padding:10px 20px;
    font-weight: 500;
    width: 100%;
	margin-bottom: 10px;
	font-size:15px;
	height:auto
}

a.btn-first-plain,
button.btn-first-plain{
	@extend a, .btn-first;
	background: $button-gradient-fallback;
}
	
a.btn-second,
button.btn-second{
	@extend .btn-new;
	background:transparent;
	border:1px solid $first-color;
	color:$first-color;
}

a.btn-second-fill,
button.btn-second-fill{
	@extend .btn-new;
	background:$first-color;
	border:1px solid $first-color;
	color:#fff !important;
}
	
a.btn-third,
button.btn-third{
	@extend .btn-new;
	background:#fff;
	color: $second-color; 
	border:1px solid $second-color;
	box-shadow: none;
	
	i{
		color: $second-color; 
	}
}

a.btn-third-plain,
button.btn-third-plain{
	@extend a, .btn-third;
	background: transparent;
    border: 0;
    border-radius: 0;
    font-weight: 500;
}

a.btn.btn-double-line,
button.btn.btn-double-line{
	padding: 5px 30px;
}

button.btn-plain{
    border: 0;
    background: none;
    outline: none;
    padding: 0;
	margin: 0;
	
	&:focus{
		outline: none;
	}
}

button.btn{
	&:hover {
		background: rgb(60,60,60);
		color: #fff;
		i{
			color:#fff;
		}

		*{
			color:#fff;
		}
	}

	&.btn-third-plain:hover{
		background:transparent;
		color:$fifth-color;
		i{
			color:$fifth-color;
		}
	}

	&.btn-small{
		height:45px;
		font-size:17px;
		padding: 5px 20px;

		&.wide{
			padding: 5px 30px;
		}
	}

	&.btn-xs{
		font-size:13px;
		height: 35px;
		padding: 5px 25px;

		&.wide{
			padding: 5px 35px;
		}
	}

	&.btn-xxs{
		height: 25px;
		padding: 3px 15px;
		font-size: 11px;
		font-weight: 600;
	}

	&.btn-back.btn-third{
		flex-direction:column;

		span{
			text-align: center;
			font-size: inherit;
			color: $second-color;
			font-weight: 600;
			opacity: 0.7;
		}
	}
}

a.btn{
	@extend button, .btn;
}

button.btn,a.btn{
	&.no-hover-bg{
		&:hover{
			background:inherit;
			background-color:inherit;
			color:inherit;
		}
	}
}


body{
    button{
        &.getMyQuoteBtn{
            max-width:250px;
        }
    
        &.small{
            font-size:14px;
        } 
    
        &.medium{
            font-size:16px;
        }
        
        &.large{
            font-size:18px;
        }
    }
}

button.btn,a.btn{
	&.input-quote-btn,&.marketplace-input-quote-btn{
		background: transparent;
		flex:1;
		position: relative;
		border: 1px solid $base-color;
		box-shadow: none;
		color: $base-color;
		font-weight: 600;
		margin: 0px 7px;
		text-transform: capitalize;
		border-radius:30px;

		&.dropdownBtnAll {
			font-size:13px;
		}

		i.fa-check{
			display:none;
			color:$second-color;
		}

		&.active{
			border:0;
			font-size:17px;
			color:#fff;

			i.fa-check{
				display:block;
				position: absolute;
				right: 0;
				top: -5px;
				color: #fff;
				background: #32b332; //$second-color;
				border-radius: 50%;
				font-size: 10px;
				padding: 5px;
			}
		}
	}

	&.input-quote-btn{
		@extend .btn-new;
	}
}

@media(max-width:1199px){
	body{
		.btn{
			font-size:18px;
		}
	}
	
}

@media(max-width:767px){
	body{
		.btn{
			font-size:16px !important;
			padding:5px 15px !important;
			max-width:100%;
			width:100%;

			&.btn-back{
				max-width:70px;
				width:100%;
			}
		}
	}
}

.saveThisPlanButton{
	height: auto;
    padding: 7px;
    background: $grey-color;
}