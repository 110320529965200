// cera
@font-face{
	font-family:'cera';
	src:url('/assets/fonts/CERA GR BLACK_4.OTF');
	font-style:normal;
	font-weight:800;
}

@font-face{
	font-family:'cera';
	src:url('/assets/fonts/CERA GR BOLD_4.OTF');
	font-style:normal;
	font-weight:600;
}

@font-face{
	font-family:'cera';
	src:url('/assets/fonts/CERA GR MEDIUM_4.OTF');
	font-style:normal;
	font-weight:500;
}

@font-face{
	font-family:'cera';
	src:url('/assets/fonts/CERA GR LIGHT_4.OTF');
	font-style:normal;
	font-weight:400;
}

@font-face{
	font-family:'cera';
	src:url('/assets/fonts/CERA GR THIN_4.OTF');
	font-style:normal;
	font-weight:200;
}

// proxima nova
@font-face{
	font-family:'proxima-nova';
	src:url('/assets/fonts/PROXIMANOVA-BLACK_0.OTF');
	font-style:normal;
	font-weight:900;
}

@font-face{
	font-family:'proxima-nova';
	src:url('/assets/fonts/PROXIMANOVA-EXTRABOLD_0.OTF');
	font-style:normal;
	font-weight:800;
}

@font-face{
	font-family:'proxima-nova';
	src:url('/assets/fonts/PROXIMANOVA-BOLD_0.OTF');
	font-style:normal;
	font-weight:700;
}

@font-face{
	font-family:'proxima-nova';
	src:url('/assets/fonts/PROXIMANOVA-SEMIBOLD_0.OTF');
	font-style:normal;
	font-weight:600;
}

@font-face{
	font-family:'proxima-nova';
	src:url('/assets/fonts/PROXIMANOVA-REGULAR_0.OTF');
	font-style:normal;
	font-weight:500;
}

@font-face{
	font-family:'proxima-nova';
	src:url('/assets/fonts/PROXIMANOVA-LIGHT_0.OTF');
	font-style:normal;
	font-weight:400;
}

// proximanova-cond
@font-face{
	font-family:'proximanova-cond';
	src:url('/assets/fonts/PROXIMANOVACOND-SEMIBOLD_0.OTF');
	font-style:normal;
	font-weight:600;
}

@font-face{
	font-family:'proximanova-cond';
	src:url('/assets/fonts/PROXIMANOVACOND-REGULAR_0.OTF');
	font-style:normal;
	font-weight:500;
}

@font-face{
	font-family:'proximanova-cond';
	src:url('/assets/fonts/PROXIMANOVACOND-LIGHT_0.OTF');
	font-style:normal;
	font-weight:400;
}

@font-face{
	font-family:'Poppins';
	src:url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}