div.main-content-wrapper,
section.main-content-wrapper{
	
	.info-section{
		text-align: center;
        padding: 15px 0;
        flex-wrap: wrap;

			p{
				margin-bottom:0;
				font-weight: 500;
                text-transform:uppercase;
                margin-bottom:5px;

				strong{
					margin-right: 5px;
					font-size:21px; // font-size: 18px;
					font-weight: 600;
					letter-spacing: 0.5px;
					@extend .font-secondary;
				}

				span{
					color: $first-color;
					font-size: 21px;
					font-weight: 600;
					letter-spacing: 0.5px;
					@extend .font-secondary;
				}
			}
		
	}
}

div.result-info-action-content,
section.result-info-action-content{
	background: #fff;
    box-shadow: 0px 60px 100px -45px #ccc;
    border-radius: 20px;
    padding: .5em 3em 2em;
    margin-top: -95px;
    position: relative;
}

.calltoaction-section{
    button{
        border-radius: 0;
        border-right: 1px solid #fff;
        text-transform: uppercase;
        height: 45px;
        max-width: 100%;
        background: $second-color;
        // padding: 0;
        font-weight: 600;
        font-size: 16px;
        
        &:first-child{
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }

        &:last-child{
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }
}


body{

    mat-divider{
        width:100%;
    }

    .result-section{
        margin-top:15px; 

        .action-content{
            width: 210px;
            float:right;
        }

        &.result-version-1{
            margin-top:65px; 
        }
        
    }

    .result-version-1{

        .result-logo{
            max-width: 300px;
            max-height: 100px;//max-height: 150px;
            margin: 0 auto 15px;
            display: block;
        }

        .rating-content{
            font-size:24px;
            font-weight:600;
            width: 70px;
            text-align: center;
            margin: auto;
        }

        .pricing-content{
            max-width:157px;
            margin:auto;

            span{
                font-size:24px;
                // margin-bottom:5px;
                font-weight:600;
            }

            select{
                // height: 52px;
                // padding: 10px 25px;
                height: 30px;
                // width: 110px;
                padding-left: 5px;
                display: block;
                margin: auto;
            }
        }

        .heading-action{
            width:210px;
            float:right;
            text-align:center;
        }

        .heading-pricing{
            text-align:center;
        }

        .heading-ratings{
            text-align:center;
        }

        th{
            text-transform:uppercase;
            color:$fifth-color;
            font-size:16px;
            font-weight:500;
            letter-spacing:0.5px;
            padding: 5px 0;
        }

        td{
            padding: 2em 0;
        }

        tr{
            border-top:1px solid $fifth-color;
            margin-bottom:1em;
            position:relative;
            display: flex;
            justify-content:space-between; //space-evenly
            align-items: center;
            flex-wrap: wrap;

            &:first-child{
                border:0;
            }

            h6.planTypeletterOrig{
                // position:absolute;
                // width:100%;
                display:none;
            }

            h4.planTypeLetterID{
                text-align:center;
            }

            &.planTypeStartLetterTr{
                td.planTypeStartLetter{
                    min-width:100%;
                    padding-bottom: 0;
                    border-bottom: 1px solid #ccc;
                    border-right: 0;
                    height: auto;
                    margin:0;
                }
            }           
        }
    }

    .result-section{
        .btn{
            font-size:12px;
            font-weight:500; 
            height: 35px;
            width: 100%; 
    
            &.btn-third-plain{
                padding:0;
            }
        }
    }
}  

/****************************************************
************** NEW QUOTES MODAL STYLES **************
*****************************************************/

.modal-quote-wrapper{
    display:flex;
    justify-content: space-between;

    .modal-quote-wrapper-form{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        max-width:405px;

        form{
           width: 100%;
        }
    }

    .modal-quote-wrapper-img{
        display:flex;
        align-items:flex-end;
        justify-content:flex-end;
        max-width: 300px;
        width: 100%;
    }
}

.quote-popup-wrapper{
    padding:30px;
}

/****************************************************
********** END NEW QUOTES MODAL STYLES **************
*****************************************************/

@media(min-width:768px) and (max-width:991px){

    //result table version 1 - 5 columns
    body .result-version-1{
        th{
            text-align:center;
            float:none;
            width:22.5%;

            &.heading-action{
                float:none;
                text-align:center;
            }

            &.heading-ratings{
                width: 10%;
            }
        }

        td{
            width:22.5%;
            
            .result-logo{
                max-width: 300px;
                max-height: 150px;
                margin: auto;
                display: block;
                margin-top:0;
            }

            .action-content{
                width: 150px;
            }

            .company-info-content {
                width: 140px;
            }  

            &:nth-child(3){
                width: 10%;
            }

            > *{
                margin:auto !important;
                float:none !important;
            }
        }
    }
}

@media(min-width:768px){
    // body .result-version-1 .pricing-content{
    //     margin: 32px auto 0 !important;
    // }  
}

@media(max-width:991px){

    // call to action button 
    div.calltoaction-section.four-buttons button.btn-small{
        border-radius:30px;
        margin-bottom: 5px;
    }
    // end clal to action button

    // info section
    .result-info-action-content{
        padding:2em !important;
    }
    // end info section

    // result table
    .result-section{
    	thead{
			display:none;
		}

		td{
			border:0;

			.result-logo{
                max-width: 300px;
                max-height: 150px;
                margin: auto;
                display: block;
				margin-top: 3em;
			}
		}
    }
    // end result table
}


// @media(max-width:840px){
//     body .result-version-1 .pricing-content select{
//         padding:10px;
//     }
// }



@media(min-width:768px){
    table.result-section tr td .result-hidden-label{
        display:none;
    }
}

@media(max-width:767px){
    // call to action button 
    div.calltoaction-section button.btn-small{
        font-size:14px;
    }
    // end clal to action button
    

    // result section
    table.container.result-section tbody > tr:nth-child(odd) {
        background: #f5f5f5;
    }

    table.container.result-section tbody > tr{
        border:0;
    }

    table.result-section{
            border:0;
    
            tr{
                margin-bottom:2em;
                display: block;
    
                td{
                    border:0;
                    display:flex;
                    justify-content:space-between;
                    align-items:flex-start;
                    padding:20px;
                    width: 100% !important;
                    
                    .result-logo{
                        max-width:300px;
                        margin:auto;
                        width:auto;
                    }
                   
                    .result-hidden-label{
                        display:block;
                        text-align:left;
                        display:block;
                        max-width:50%;
                        width:100%;
                        text-transform:uppercase;
                        font-weight:500;
                    }
    
                    .result-value{
                        text-align:right;
                        display:block;
                        flex:auto;
                    }
    
                    &.result-button-td{
                        flex-direction:column;
                    }  
                    
                    .result-button-wrapper{
                        width:100%;

                        > .btn-third-plain{
                            border-bottom: 1px solid #d8d8d8;
                        
                            &:last-child{
                                border:0;
                            }
                        }
                    }

                    .rating-content{
                        margin:0;
                        width:auto;
                    }

                    .pricing-content{
                        margin:0;
                    }
                }
            }
    }

    .main-content-wrapper{
        .result-version-1{
            tr{
                &:first-child{
                    display:none;
                }

                td{
                    .onMobileTabletResultValue{
                        text-align:right;
                    }
                }
            }
        }
    }
    // end result section

    div.main-content-wrapper .info-section p strong, 
    section.main-content-wrapper .info-section p strong,
    div.main-content-wrapper .info-section p span, 
    section.main-content-wrapper .info-section p span{
        font-size: 18px;
    }

    body .result-version-1 .pricing-content span{
        font-size: 20px;
    }
    body .result-version-1 .rating-content{
        font-size: 20px;
    }

    .result-version-1 tr .result-ratings-wrapper,
    .result-version-1 tr .result-pricing-wrapper{
        padding-bottom:0
    }

}

@media(max-width:575px){
    // call to action button 
    div.calltoaction-section button.btn-small{
        border-radius:30px;
        margin-bottom: 5px;
    }
    // end clal to action button

    div.main-content-wrapper .info-section p, 
	section.main-content-wrapper .info-section p{
		width:100%;
	}

    div.main-content-wrapper .info-section p strong, 
    section.main-content-wrapper .info-section p strong,
    div.main-content-wrapper .info-section p span, 
    section.main-content-wrapper .info-section p span{
        font-size: 16px;
    }
    // end info section

    // info section
	div.main-content-wrapper .info-section{
        p{
            display:flex;
            justify-content:space-between;
            width: 100%;
            align-items: flex-start;
            margin: 0 0 5px 0 !important;
        }
    }

}

@media(max-width:480px){

    div.main-content-wrapper .info-section p, 
    section.main-content-wrapper .info-section p{
        display: flex;
        // flex-direction: column;
        align-items: center;  
    }

    div.main-content-wrapper .info-section p strong, 
    section.main-content-wrapper .info-section p strong{
        margin-right:0;
    }

    .result-info-action-content {
        padding: 1em !important;
    }

}

body .result-version-1 tr{
    th{
        &.heading-company{
            width: 30%;
        }
        &.heading-company-info{
            width: 20%;
            padding-left: 1em;
        }
        &.heading-ratings{
            width: 10%;
        }
        &.heading-pricing{
            width: 18%;
        }
        &.heading-action{
            width: 22%;
        }
    }
    td{
        &.result-logo-wrapper{
            width: 30%;

            span{
                display: block;
                text-align: center;
                font-weight: 500;
                margin-top: 15px;
                color: #a5a5a5;
            }
        }
        &.result-company-info-wrapper{
            width: 20%;
            padding-left: 1em;
        }
        // &:nth-child(2){
        //     width: 20%;
        //     padding-left: 1em;
        // }

        &.result-ratings-wrapper{
            width: 10%;
        }

        // &:nth-child(3){
        //     width: 10%;
        // }

        &.result-pricing-wrapper{
            width: 18%;
        }

        // &:nth-child(4){
        //     width: 18%;
        // }

        &.result-action-wrapper{
            width: 22%;
        }

        // &:last-child{
        //      width: 22%;
        // }
     }
}

/****************************************************************************************************
***********************************NEW DESIGN FOR MARKETPLACE RESULT*********************************
******************************************************************************************************/
.result-version-5 {
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 0 2rem;

    table.result-section{
        margin-top: 0;

        tr{
            &.tr-result-heading{
                height: auto;
                padding: 10px 0;
                margin-bottom: 0;
                border-top:0;

                > th{
                    &:first-child{
                        width: 24%;
                    }   
                    
                    &.heading-company-info{
                        padding-left: 0;
                    } 

                    width: 19%;
                    text-transform:uppercase;
                    color:$fifth-color;
                    font-size:16px;
                    font-weight:500;
                    letter-spacing:0.5px;
                    padding: 5px 0;
                    text-align:center;
                }
            }

            &.planTypeStartLetterTr{
                height: auto;

                td{
                    &.planTypeStartLetter{
                        min-width: 100%;
                        padding-bottom: 0;
                        border-bottom: 1px solid #ccc;
                        border-right: 0 !important;
                        height: auto !important;
                        margin: 0 !important;
                        margin-bottom: 15px !important;
                    }
                    height:170px;
                }
            }

            td{
                &.result-logo-wrapper{
                    width: 24%;                    

                    img.result-logo {
                        max-width: 95%;
                        max-height: 100px; //max-height: 150px;
                        margin: 0 auto 15px;
                        display: block;
                    }
                }                

                &.result-company-info-wrapper{

                    .result-button-wrapper{

                        button.btn-third-plain{
                            height: 30px;
                            padding:0;
                        }

                        .mat-divider{
                            width:110px;
                        }                    
                    }
                }

                &.result-ratings-wrapper{
                    padding-top: 15px;

                    .rating-content{
                        font-size:28px; //font-size: 24px;
                        font-weight: 600;
                        //width: 70px;
                        text-align: center;
                        // margin: -8px auto 2px;
                        color: $first-color;
                        line-height:1em;
                    }
                }

                &.result-pricing-wrapper{
                    padding-top: 15px;
                    
                    .pricing-content{
                        max-width: 157px;
                        margin: auto;

                        span{
                            display:block;
                            width:100%;
                            text-align:center;                            
                            color: $first-color;
                            font-weight: 600;
                            text-transform: uppercase;

                            &.price-value {
                                font-size: 28px;
                                font-weight: 600;
                                color:#000;
                            }
                        }
                    }
                }

                &.result-action-wrapper{
                    .action-content{
                        width: 178px;
                    }

                    .result-action-compare-add{
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        margin-top: 10px;//margin-top: 20px;

                        label.custom-control-label{
                            font-size: 14px;
                        }
                    }
                }
                

                width: 19%;
                border-right: 1px solid #e0e0e0;
                height: 100%;
                padding: 10px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;                

                button.btn.btn-first {
                    max-width: 180px;
                }

                &:last-child{
                    border-right:0;
                }

            }

            border-top: 1px solid #e0e0e0;
            padding: 20px 0px;
            height: 222px;
            margin-bottom: 0;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}

div.result-info-action-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.result-info-action-content-plan-typewrapper{
	display: block;
    margin: -33px auto 0;
    border: 3px solid $second-color;
    border-radius: 30px;
    padding: 2px 20px;
    color: $first-color;
    font-weight: 600;
    text-align: center;
    background-color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    max-width: 100%;
    box-shadow: 0px 3px 12px #8f8f8f;
}

.result-include-icons{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
  
    img{
      padding: 0 !important;
      //max-height: 40px;
      max-height: 30px;
      margin-bottom: 15px;
      width: 50%;

      &.result-vision-icon{
        width: 50%;
        margin-bottom: 0;
        margin-top: 18px;
      }

      &.result-hearing-icon{
        width: 50%;
        margin-top: 10px;
        padding: 8px;
      }
    }
  }

  .result-action-compare-add{
    div.compared-plans-btn-wrapper{
        margin-right: 0 !important;
    
        a.viewComparePlansBtn{
            padding: 0;
            height: auto;
            font-size: 12px;
            margin-top: 5px;
          }
      }
  }
 

.result-policies-available-line{
    font-weight:600;
    text-transform:capitalize;
    text-align:center;
    margin-bottom:35px;
    font-size: 24px;
    color:$first-color;
    margin-top: 25px;
}

.no-img-plan-name{
    background-color:$first-color;
    color:#fff;
    text-align:center;
    border-radius:4px;
    padding: 5px 10px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}

.column-triple-content-inner .result-include-icons > div {
    width: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0;
    height: 40px;

    img{
        width: auto !important;
        margin:0 !important;

        &.result-hearing-icon{
            width: 25px !important;
        }

        &.result-vision-icon{
            width: 40px !important;
        }

        &.result-includes-no-icon{
            width: 35px !important;
        }
    }
}

div.mapd-result-version-5.type-1 .mapd-result-table tr{
    td.costs-wrapper{
        padding-bottom: 45px !important;
    }
    td{
        span.main-value{
            line-height: 1.2rem !important;
        }
    }
}


.results-plan-name{
    font-size: 14px;
    color: $base-color;
    font-weight: 500;
    text-transform: capitalize !important;
}