// typography
// .custom-info-modal-wrapper p{
// 	font-size:22px;
// }

.swal2-popup{
	&.custom-info-modal-wrapper{
		padding: 35px 50px;
		
		.global-info-modal{
			h4{
				margin-bottom:1em;
				// text-align:center;
			}

			p{
				font-size: 22px;
				// text-align: center;
				color: #000;
				margin-bottom: 30px;
				line-height: 1.4em;
			}
			
			img{
				position:absolute;
				right: -145px;
				bottom: -35px;
			}
		}	
	}
}

.modal-LeftImgWrapper{
    flex:auto;

    img{
        position: absolute;
        // right: -80px;
        max-width: 495px;
    }
}

.modal-RightWrapper{
    max-width:705px;
    width:100%;
	// background:skyblue;
	padding: 15px;
}

// apply online and apply on the phone modal

.modal-companyLogo{
	margin: 0 auto;
    display: block;
	max-width: 415px;
	width:100%;
}

.mail-order-wrapper{
    table.modal-table{
        text-align:center;
        tr{
            td{
                font-weight:500;
                padding:20px;
            }

            th{
                background:$first-color !important;
                color:#fff;
                width:25% !important;
                font-size: 22px;
                text-transform: capitalize;
                padding:20px !important;
            }
        }
    } 
}

.applyOnlineSlideWrapper{
	
	padding: 0 45px 20px;

	h3{
		font-size: 24px;
		text-transform: uppercase;
		font-weight: 700;
		color:$first-color;
		margin-bottom: 2em;
	}
	p{
		font-size:20px;
		line-height: 22px;
		letter-spacing: 0.5px;

		&.uppercase{
			font-weight:600;
			font-size: 18px;
			margin: 3em 0 1em;
		}
	}

	.applyOnlineSlideWrapper1{
		button{
			display: block;
			margin: 3em auto 0;
		}
	}

	.applyOnlineSlideWrapper2{
		.button-wrapper{
			display:flex;
			justify-content:space-around;
			margin:3em auto 0;
			width:100%;
		}
	}
}

.modal-large{
	img.modal-img-character{
		max-width:265px;
		right: -136px;
    	bottom: -26px;
	}
}

.modal-medium{
	img.modal-img-character{
		max-width:200px;
		right: -126px;
    	bottom: -77px;
	}
}

.modal-small{
	img.modal-img-character{
		max-width:170px;
		right: -70px;
    	bottom: -40px;
	}
}

/*
SHARE PLAN - LOGIN MODAL
*/
.swal-shareplan {
	width: 500px !important;
	padding-bottom: 0 !important;
	
	.share-plan-content {
	  img {
		display: flex;
		justify-content: center;
		width: 250px !important;
		margin: 0 auto;
	  }
  
	  .login-sso-icons {
	  
		span {
		  display: flex;
		  justify-content: center;
		  margin-bottom: 20px;
		}
  
		.icon-group {
		  display: flex;
		  justify-content: center;
  
		  svg {
			width: 50px;
			margin: 0 10px;
		  }
		}
	  }
  
	  .or-separator {
		position: relative;
	  
		span {
		  background: #fff !important;
		  position: absolute;
		  left: 50%;
		  top: -10px;
		  -webkit-transform: translateX(-50%);
		  transform: translateX(-50%);
		  margin: auto;
		}
	  }
  
	  .share-plan-login-input-group {
		input {
		  padding: 5px 10px;
		  border: solid 2px lightgray;
		  margin: 10px 0;
		}
	  }
  
	  .share-plan-login-submit-btn {
		background-color: #f8e042;
		border: unset;
		padding: 10px 50px;
		border-radius: 50px;
		color: #666;
	  }

	  .share-plan-login-footer {
		display: flex;
		justify-content: center;
		margin: 50px 0 10px 0;

		span {
			font-size: 12px;

			a {
				color: rgb(61, 126, 245) !important;
				text-decoration: underline;
				font-size: 14px !important;
			}
		}
	  }
	}
  }

/*
SHARE PLAN - CARRIER MODAL
*/
.swal-shareplan-carrier {
	position: relative;
	width: 600px !important;
	padding-top: 20px !important;

	.swal2-title {
		color: $first-color !important;
	}

	.carrier-first-page {
		
		.carrier-img-content {
			position: absolute;
			margin-top: -40px;
			margin-left: -100px;
		}

		.carrier-main-content {

			h4 {
				color: rgb(86,86,86) !important;
			}
			
			.carrier-rating-breakdown {
				margin: 20px 0;

				.group-rating {
					display: flex;
					justify-content: space-between;

					.star-group {
						padding-left: 15px;
					}
				}
			}

			.carrier-btn-group {
				display: flex;
				justify-content: center;

				button {
					background: linear-gradient(90deg, #ffa624 50%, #fae453 100%);

					&:hover {
						background: #3c3c3c;
					}
				}
			}
		}
	}

	.carrier-second-page {
		.carrier-img-content {
			position: absolute;
			margin-top: -40px;
			margin-left: -100px;

			img {
				width: 60%;
			}
		}

		.carrier-main-content {
			
			.top-note {
				max-width: 300px;

				span {
					font-size: 12px;	
				}
			}

			.second-page-content {
				display: flex;
    			padding-left: 50px;

				.second-page-left-note {
					width: 50%;

					h5 {
						font-size: 24px;
						font-weight: 700;
						text-align: center;
					}
				}

				.second-page-right-form {
					width: 100%;

					.page-input-group {
						display: grid;
						margin: 10px 20px;

						label {
							margin-bottom: 0 !important;
							font-size: 14px;
							font-weight: 600;
						}

						input {
							padding: 5px 10px;
    						font-size: 14px;

							&:placeholder-shown {
								font-style: italic;
							}
						}
					}

					.carrier-btn-group {

						button {
							font-size: 16px;
							height: auto;
							padding: 15px 40px;
							background: linear-gradient(90deg, #ffa624 50%, #fae453 100%);

							&:hover {
								background: #3c3c3c;
							}
						}
					}
				}
			}

			.footer-note {
				font-size: 12px;
				justify-content: center;
				position: absolute;
				bottom: 20px;
				left: 0;
				right: 0;
			}
		}
	}

	.carrier-third-page {

		.carrier-img-content {
			position: absolute;
			margin-top: -40px;
			margin-left: -100px;
		}

		.carrier-main-content {
			padding-left: 50px;

			.third-page-question {
				h5 {
					color: rgb(86, 86, 86) !important;
					font-size: 18px;

					b {
						color: $warning-color;
					}
				}
			}

			.third-page-button-container {

				.carrier-btn-group {
					justify-content: center;

					button {
						padding: 0 30px;
    					height: 60px;
					}

					.btn-third {
						justify-content: flex-end;
					}

					.btn-first-plain {
						background: linear-gradient(90deg, #ffa624 50%, #fae453 100%);
						justify-content: flex-start;

						&:hover {
							background: #3c3c3c;
						}
					}
				}
			}
		}
	}
}

/*
Benny Dash Access Settings - Edit Access
*/
.edit-access-modal-popup {
	width: 600px !important;

	#swal2-content {
		.password-success{
			font-size: 24px;
			font-weight: 500;
			text-align: center;
		}
	}
}

.swal-overall-experience-review {
	width: 500px !important;

	.overall-review-experience-container {
		img {
			display: flex;
			justify-content: center;
			width: 250px !important;
			margin: 0 auto;
		}

		h5 {
			font-size: 16px;
			font-weight: 500;
		}

		.overall-review-main-button {
			background-color: #f8e042;
			color: #888787;
			transition: .2s;
			border: unset;
			border-radius: 100px;
			padding: 10px 30px;
			font-size: 24px;

			&:hover {
				background: #3c3c3c;
				color: #fff;
			}
		}

		.overall-review-alt-button {
			background-color: #fff;
			border: solid 1px lightgray;
			border-radius: 50px;
			font-size: 10px;
			color: #565656;
			font-weight: 600;
			padding: 10px 15px;

			&:hover {
				background-color: rgb(248, 224, 66);
				color: rgb(86, 86, 86);
			}
		}

	}
}

.swal-overall-star-review {
	width: 600px !important;

	.overall-star-rating-container {
		img {
			display: flex;
			justify-content: center;
			width: 250px !important;
			margin: 0 auto;
		}

		h5 {
			font-size: 16px;
			font-weight: 500;
		}

		.star-content {
			justify-content: center;
		}
		
		.overall-review-main-button {
			background-color: #f8e042;
			color: #888787;
			transition: .2s;
			border: unset;
			border-radius: 100px;
			padding: 10px 30px;
			font-size: 18px;

			&:hover {
				background: #3c3c3c;
				color: #fff;
			}
		}
	}
}

.remove-from-list-modal {
	#swal2-content {
		text-align: center !important;
	}
	.swal2-actions {
		button {
			border-radius: 50px;
		}
	}
}
//==================RESPONSIVE=================

@media(max-width:1199px){
	.modal-RightWrapper{
		max-width:70%;
	}

	.modal-LeftImgWrapper img{
		position:unset;
		max-width:100%;
	}
}

@media(max-width:1050px){

	.modal-actionWrapper{
		flex-direction: column;
		align-items:center;
	}
	
}



@media(max-width:767px){
	.modal-actionWrapper{
		flex-direction: column;
		align-items: center;
	}	

	.modal-RightWrapper{
        max-width:100%;
    }
    
    .modal-LeftImgWrapper{
        display:none;
	}
	
	.button-wrapper{
		flex-direction: column !important;
		align-items: center !important;
		justify-content:center !important;
		margin:3em auto 0;
		width:100%;
		
		button{
			margin-bottom:1em;
		}
	}
}



@media(max-width:575px){
	.swal2-popup.custom-info-modal-wrapper .global-info-modal img{
		display:none;
	}
}

@media(max-width:480px){
	.modal-popupWrapper table.table-rating-summary td{
		padding:15px;
	}	
}
