// colors
$first-color:#156aff; // blue 
$second-color: #ffa624; // orange
$third-color: #fae453; // yellow
$fourth-color:#646464; // use for table text
$fifth-color:#666666;
$sixth-color:#cccccc;
$hover-color:#3c3c3c;
$warning-color:#FF5252;
$base-text-color: $fifth-color;   //#101742;
$text-color-middle:#55566d;
$global-app-background: #ffffff; // white background
$global-app-background-secondary: #f1f1f1; // offwhite background
$global-box-border-color: #55566d33;
$light-orange-color:#feb832;
$base-color: $fourth-color;
$green-color:#90EE90;
$dark-green-color:#1db719;
$dirty-white-color:#fffadb;

$first-color-dark: rgb(0,0,140);

$gold-color:#f8e352;

$grey-color:#919191;
$faint-grey-color:#bdbdbd;

// FONT FAMILY:
$font-family-primary: "cera",Arial, "Open-sans";
$font-family-secondary: "proxima-nova",Arial, "Open-sans";

//buttons
$btn-border-radius:40px;
$button-shadow:-3px 4px 24px -16px #000;
$button-gradient: linear-gradient(90deg, rgba(255,166,36,1) 50%, #FFDC5B  120%); 
$button-gradient-fallback:rgb(255,166,36);
// $button-padding:5px 30px;
$button-padding:5px 45px;
$button-height:72px;

$bg-gradient: linear-gradient(90deg, rgba(255,166,36,1) 50%, rgba(250,228,83,1) 100%); 


//boxes
$border-radius:15px;
// $box-shadow:-5px 5px 30px -19px #525252;
$box-shadow: 0px 0px 30px 20px #bfb6b626;


// color palette variables
$gold: linear-gradient(90deg, #ffa624 50%, #fae453 100%);
$blue: #156AFF;
$dblue: #003DA8;
$lblue: #47C6F1;
$orange: #FFA700;
$yellow: #FFDD00;
$lgray: #818181;
$gray: #5B5B5B;
$dgray: #3A3A3A;
$beige: #EDE3B2;

// font weights
$light: 300;
$normal: 400;
$medium: 500;
$bold: 600;
$black: 700;