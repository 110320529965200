//====STYLING FOR LOGINS, REGISTRATION, FORGOT PASSWORD, UPDATE PASSWORD FORMS============================

.access-forms{
    padding-top: calc(55px + 45px);

    h4.fColor-base{
        margin-bottom: 0.8em;
    }

    .primary-box{
        background: #fff;
        border-radius: 15px;
        position: relative;
        
        .box-top{
            padding: 3em 3em 0;
        }

        .box-bottom{
            background:#f1f1f1;
            border-radius: 15px;
            padding:1em 3em;
        }
    }

    .header-banner-wrapper {
        background-image: url(/assets/img/registration/second-background.png);
    }

    label sup{
        margin-left: 5px;
        opacity: 0.8;
        // top: 5px;
        // font-size: 1.2em;
    }

    form .form-group{
        max-width:100%;
    }

    .box-wrapper{
        padding-left:40px;
        margin-bottom:20px;
    }

    .form-character-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
//======================responsive================

@media(max-width:767px){
    .access-forms{
        .primary-box .box-top{
            padding:2em;
        }
    
        .signup-img{
            display:none;
        }
        .box-wrapper{
            padding-left:0;
        }
    
        .recaptcha-wrapper{
            transform: scale(0.8);
            -moz-transform: scale(0.8);
            -webkit-transform: scale(0.8);
        }
        
        .form-character-wrapper h4{
            margin-bottom:1em;
        }
    }    
}
