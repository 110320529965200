.marketplace-widget-btn{
    height: 55px;
    width: 230px;
    // width: 210px;
    background-size: contain !important;
    background:url('/../../../../assets/img/common/icons/marketplace-guidance-v2.png');
    // background-image:url('/../../../../assets/img/common/icons/marketplace-widget-btn-icon.svg');
    background-repeat: no-repeat;
    position: fixed;
    right: -172px;
    // right: 25px;
    bottom: 50%;
    border: 0;
    background-color: transparent;
    outline: 0 !important;
    box-shadow: none;
    z-index:1;
    transition: all 0.3s ease-in-out;
    animation: attention 5s ease infinite;
    &:hover {
        right: -24px;
        animation: none;
    }
}

@keyframes attention {
    9% {
      transform: none;
    }
    12% {
      transform: translateX(-8px);
    }
    15% {
      transform: translateX(-4px);
    }
    18% {
      transform: translateX(-7px);
    }
    21% {
      transform: translateX(0px);
    }
}

@media only screen and (max-width: 991px) {
    .marketplace-widget-btn {
        bottom: 25px !important;
    }
}

@media only screen and (max-width: 575px) {
    .marketplace-widget-btn {
        right: 16px !important;
        bottom: 20px !important;
    }
}

.bennyDash-widget-btn{
    height: 120px;
    width: 120px;
    background-size: contain !important;
    background:url('/../../../../assets/img/common/icons/marketplace-guidance.png');
    // background-image:url('/../../../../assets/img/common/icons/marketplace-widget-btn-icon.svg');
    background-repeat: no-repeat;
    position: fixed;
    right: 25px;
    bottom: 55px;
    border: 0;
    background-color: transparent;
    outline: 0 !important;
    box-shadow: none;
    z-index:1;
    transform: scale(.5);
    transition: .2s;

    &:hover {
        transform: scale(1) !important;
    }
}

#marketplace-widget-form,#bennyDash-widget-form{
    width: 700px;
    /* height: 400px; */
    background: #fff;
    /* border: 1px solid black; */
    border-radius: 10px;
    position: fixed;
    bottom: 25px;
    right: 25px;
    // padding: 25px 0 0 35px;
    box-shadow:0 0 0 0 #000;
    z-index: 15001;
    display:none;
    // overflow: hidden;
}

.marketplace-widget-form-close,.bennyDash-widget-form-close{
    position:absolute;
    top:5px;
    right:5px;
    border: 0;
    background: none;
    outline: 0 !important;

    i{
        font-size: 18px;
    }
}

.marketplace-widget-overlay,.bennyDash-widget-overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 15000;
}

#bennyDash-widget-form{
    width: 540px;
    height: 280px;
}

.bennyDash-widget-btn{
    background-image:url('/../../../../assets/img/common/benny-dash-support.png');
}