.contact-top{
    form{
        .form-group{
            max-width:100%;
        }

        button.btn-first{
            padding: 20px 40px;
            min-width: 270px; 
        
        
            &.btn-back{
                padding:0;
                min-width:auto;
            }
        }
    }

    .form-left-img{
        img{
            max-width: 100%;
            margin-left: 0;
        }
    }
}