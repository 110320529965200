.iw-content-fluid{
    width:calc(100vw - 120px);
    padding: 0 3rem 3rem;
}

.iw-content-wrapper{
    display:flex;
    background:#f3f3f3;
}

.iw-slide-nav{
    display: flex;
    align-items: center;
    width: 60px;
    background:transparent;

    button{
        width: 50px;
        height: 100px;
        border-radius: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &.iw-slide-nav-left{    
            border-bottom-right-radius: 50px;
            border-top-right-radius: 50px;
        }

        &.iw-slide-nav-right{    
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }

        i{
            font-size: 1.5rem;
        }
    }
}

// white box option wrapper
.box-wrapper{
    // width:33%;
    max-width:400px;
    width:100%;
    > h5{
        color:#000;
        padding:1.5rem;
    }
}


// DEFAULT BOXES
.box-inner{
    background:#fff;
    padding:2rem;
    border-radius:10px;
    max-width: 750px;

    .box-text{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding: 0;

        h5{
            display: flex;
            flex-direction: column;

            span{
                font-size: 1rem;
                margin-top: 5px;
                color: inherit;
                font-weight: 500;
            }
        }

        i{
            color:$first-color;
            font-size: 1.5rem;
            margin-left: 2rem;
        }
    }

    mat-divider{
        margin: 1.5rem 0;
        border-top: dashed #ccc;
    }
}

// CHECKBOX VERSION BOXES




.checkbox-version{
    h5{
        span{
            display: block;
            font-size: .8rem;
            margin-top: 8px;
        }
    }

        .custom-control{
            padding-left:0;
            padding-right: 3rem;
        }

        .custom-control-label::after,.custom-control-label::before{
            left: unset;
            right: -3rem;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            width: 25px;
            height: 25px;
            border: 1px solid #9f9f9f;
        }

        label.custom-control-label{
            font-size: 23px;
            font-weight: 700;
            text-transform: uppercase;
            width: 100%;
            color:$first-color;

            span{
                display: block;
                font-size: .8rem;
                text-transform: capitalize;
            }
        }
     
}


.form-group-wrapper{
    max-width:600px;
    width:100%;
    
    .form-group{
        margin-bottom: 3rem;

        label{
            text-transform: uppercase;
            font-weight: 600;
            color: #000;
            margin-bottom: 1rem;
        }

        select, input{
            height: 80px;
            border: 0;
            box-shadow: 0px 1px 4px 0px #d6d6d6;
            padding: 10px 1.5rem;
            border-radius: 10px;
            border-radius: 10px;
        }
    }
}

//form group checkbox version
.form-group-checkbox{
    .custom-control{
        padding-left:0;
        
        .custom-control-label{
    
            height: 80px;
            border: 0;
            box-shadow: 0px 1px 4px 0px #d6d6d6;
            padding: 10px 1.5rem;
            border-radius: 10px;
            background: #fff;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        
            &:before, &:after{
                right: 1.5rem;
            }
            span{
                padding-right: 3rem;
                font-weight: inherit;
                color: $first-color;
                font-size: 18px;
            }
        }
    }
    
}



// SELECT VERSION
.iw-select-wrapper {
    position: relative;
}

.iw-select-wrapper:after {
    content: '\f078';
    font: normal normal normal 17px/1 FontAwesome;
    color: $first-color;
    right: 1rem;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    pointer-events: none;
}

.iw-select-wrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


/*=========================================================================
  ====================RESPONSIVE QUERIES===================================
  =======================================================================*/

@media(max-width:575px){
    .desktop-only{
        display:none;
    }

    .iw-slide-nav{
        z-index:1;
        button {
            width: 30px;
            height: 60px;
        }
    }

    .iw-slide-nav button.iw-slide-nav-left {
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
    }

    .iw-slide-nav button.iw-slide-nav-right {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    .iw-slide-nav button i {
        font-size: 1rem;
    }
    
    html body p{
        font-size:16px;
    }

    .box-inner h5{
        font-size:20px;
    }

    .box-inner .box-text{
        padding:8px;
    }

    // responsive

    .iw-content-fluid{
        padding:0;
        width: calc(100vw - 60px);
    } 

    .iw-slide-nav{
        width: 30px;
    }

    .box-inner{
        padding:0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .checkbox-version .custom-control-label::after, 
    .checkbox-version .custom-control-label::before {
        right: -2rem;
        width: 18px;
        height: 18px;
    }

    .checkbox-version label.custom-control-label {
        padding-left: 10px;
        font-size: 18px;
    }

    .box-inner.summary .box-text button {
        margin-left: 1rem;
        max-width: 60px;
        font-size: 14px !important;
    }

    .box-inner.summary > button {
        padding: 1rem !important;
        height: auto;
        width: auto;
        margin: auto;
    }

}

@media(min-width:576px){
    .mobile-only{
        display:none;
    }
}
