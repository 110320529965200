@import './retiree_dashboard_variables';

// resetter
p{
    margin:0;
    padding:0;
}

body b, body strong{
    font-weight:600 !important;
    color:inherit;
}


/*************************************************************************
*************************FRONT PAGE - CLASSIC*****************************
*************************************************************************/

.retiree-page-box.front-page.classic{
    background:#fff;

    .retiree-page-box-header{
        background: #156aff;
        height: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom-right-radius: 80px;
        padding-bottom: 4em;

        img{
            margin-bottom: 2em;
        }

        h1{
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 20px;
            padding: 20px;
        }

        p{
            color: #fff;
            max-width: 468px;
            text-align: center;
            font-size: 22px;
        }
    }
    .front-page-center{
        background:$first-color;
        height:auto;

        .front-page-center-content{
            background:#fff;
            justify-content: center;
            align-items: flex-start;

            img.front-page-main-img{
                position:unset;
                margin-top: -4em;
                max-width: 280px;
            }
        }
    }

    .front-page-footer{
        height:auto;
        padding: 0 2em 2em;
    }

    .front-page-footer-quote{
        border: 2px dashed #d8d4d4;
        margin: 0 auto;
        position: relative;
        padding: 10px;

        p{
            font-weight: 600;  
        }

        span{
            position: absolute;
            right: 15px;
            bottom: -10px;
            background: #fff;
            font-weight: 600;
            color: $first-color;  
        }
    }
    
}


/*************************************************************************
*************************FRONT PAGE - SUB INTRO***************************
*************************************************************************/
.front-page.sub-intro .front-page-footer{
    img{
        margin-top: -180px;
        position: relative;
        z-index: 1;
    }
    .orange-btn{
        background:orange;
        font-weight:600;
        color:#000;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding:10px;
        margin-left: -123px;
        margin-top: 60px;
    }
}


.front-page.sub-intro .front-page-header {
    height: 125px;

    img{
        max-width: 300px;
        display: block;
        margin: auto;
    }
}

/*************************************************************************
*********************************HEADER***********************************
*************************************************************************/

.retiree-page-box-header{
    height: 65px;
    border-bottom: 1px solid #efefef;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 1em;
}

.retiree-page-box-header h6{
    text-align: center;
    padding: 20px;
    color: #d0d0d0;
    text-transform:uppercase;
    margin-bottom:0;
}

.retiree-page-box-header i{
    font-size: 2em;
    color: $rd-orange;
}

.front-page-header {
    background: #fff;
    border-bottom-right-radius: 80px;
    -webkit-box-flex: 1;
    flex: auto;
    width: 100%;
    height: 80px;
}




/*************************************************************************
*********************************FOOTER***********************************
*************************************************************************/

.front-page-footer {
    background: #fff;
    border-top-left-radius: 80px;
    -webkit-box-flex: 1;
    flex: auto;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 200px;
    padding: 2em;
}


/*************************************************************************
************************COMMON ELEMENTS - TABLE **************************
*************************************************************************/

table.table.rd-table{
    width:100%;
    margin-top:1em;

    td,th{
        vertical-align:middle;
    }

    th{
        background:#f1f1f1;
        text-transform:uppercase;
        font-weight:bold;
    }
}

.rd-horizontal-divider{
    width:100%;
    height:1px;
    border-bottom:1px dashed $rd-gray;
    margin:3em 0;
}

/*************************************************************************
************************COMMON ELEMENTS - FIRST PAGE *********************
*************************************************************************/

.retiree-page-box-content{
    // margin-top:80px;
    padding: 2em;
    position:relative;
}

.rd-box-head-v1{
    height: 100px;
    background: $first-color;
    width: 100%;
    display: -webkit-box;
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    border-top-left-radius: 35px;
    border-bottom-right-radius: 35px;
    margin-bottom: 2em;
    padding: 0 2em;
    margin-top: 4em;

    h5{
        color:#fff;
    }

    img{
        align-self: flex-end;
        margin-bottom:-25px;
    }
}

// >>>>>>>> OVERVIEW 5 BOXES WRAPPER <<<<<<<
.overview-coverage-4-boxes-wrapper{
    display:flex;
    justify-content:space-between;
    margin-bottom:1em;
    max-width: 1120px;
    width: 100%;
    margin: 1rem auto 2rem;

    .rd-oc-box-v1-plus{
        display: flex;
        align-items: center;
        width: 5px;
        position: relative;

        i{
            background: $first-color;
            color: #fff;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
        }
    }

    .overview-coverage-4-inner{ 
        width: 185px;
        text-align:center;
        background:#f1f1f1;
        padding-bottom:1em;
        border-radius: 5px;
        opacity: 0.5;

        h6{
            background:$first-color;
            color:#fff;
            padding: 15px 2em;
            text-transform:uppercase;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            font-size: 24px;
        }

        p{
            color:$first-color;
            font-weight:500;
            padding: 0 1em;
            line-height: 1em;
            height: 35px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            font-size: 18px;
        }

        img{
            margin: auto;
            display: block;
            width: 100%;
            margin-bottom:1em;
            max-width: 90px;
            height: 70px;
        }

        b{
            text-transform: uppercase;
            display:block;
            font-size: 18px;
            padding: 0 20px;
        }

        &.rd-highlighted{
            opacity: 1;
            outline: 9px solid orange;
            z-index: 1;
        }
    }
}

/*************************************************************************
************************COMMON ELEMENTS - ORANGE BUTTON PAGE *************
*************************************************************************/
// ul li {
//     font-weight: 500;
// }

.box-heading-radius{
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding:1em;
    font-weight: bold;
    text-transform: uppercase;
    color:#000;
    flex:1;

    &.box-orange{
        background:$rd-orange;
    }
}


/*************************************************************************
************************CLASS DECLARATION ********************************
*************************************************************************/

.rd-bgColor-gray{
    background-color:$rd-gray;
}

.rd-fColor-black{
    color:$rd-black;
}

.rd-fColor-orange{
    color:$rd-orange;
}

.rd-bgColor-orange{
    background-color:$rd-orange;
}

.with-br .bbrr{
    border-bottom-right-radius: 80px;
}

.with-br .bblr{
    border-bottom-left-radius: 80px;
}

.with-br .btrr{
    border-top-right-radius: 80px;
}

.with-br .btlr{
    border-top-left-radius: 80px;
}

.with-br .bbrr-medium{
    border-bottom-right-radius: 40px;
}

.with-br .bblr-medium{
    border-bottom-left-radius: 40px;
}

.with-br .btrr-medium{
    border-top-right-radius: 40px;
}

.with-br .btlr-medium{
    border-top-left-radius: 40px;
}


.with-br .bbrr-small{
    border-bottom-right-radius: 30px;
}

.with-br .bblr-small{
    border-bottom-left-radius: 30px;
}

.with-br .btrr-small{
    border-top-right-radius: 30px;
}

.with-br .btlr-small{
    border-top-left-radius: 30px;
}





/*************************************************************************
************************FRONT PAGE  **************************************
*************************************************************************/


.retiree-page-box {
    background: #fff;
    border-radius: 10px;
    min-height: 780px;
    margin-bottom: 2em;
    position: relative;

    &.front-page{
        background: #156aff;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;

        .retiree-page-box-header{
            border:none;
            justify-content:flex-end;
        }

        .front-page-center{
            background: #fff;
            position: relative;
            z-index: 1;
            width: 100%;
            height: 500px;

            .front-page-center-content{
                background: #156aff;
                border-top-left-radius: 80px;
                width: 100%;
                height: 100%;
                display: -webkit-box;
                display: flex;
                -webkit-box-pack: start;
                justify-content: flex-start;
                -webkit-box-align: center;
                align-items: center;
                padding: 2em;

                h1{
                    color: #fff;
                    max-width: 380px;
                }
            }
        }   

        img.front-page-main-img{
            position: absolute;
            z-index: 1;
            bottom: -77px;
            right: -60px;
            max-width: 450px;
        }  
    } 

    &.version-2{
        background:#f6f6f6;
        
        .front-page-center{

            .front-page-center-content{
                
                background:#f6f6f6;

                h1{
                    color: $first-color;
                }
            }
        }
    }
}

section.retiree-dashboard-section .info-section {
    text-align: center;
    flex-wrap:wrap;

    p{
        width: 33%;

        strong{
            margin-right: 5px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.5px; 
        }
        span{
            color: #156aff;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.5px;
        }
    }
    
}




/**********************************************************************************
***************************NEW BENEFICIARY DASHBOARD DESIGN************************
***********************************************************************************/ 
.beneficiary-version{
    background: #f7f7f7;
    height:calc(100vh - 90px);
    display:flex;

    .beneficiary-container{
        display:flex;

        app-beneficiary-dashboard-navigation{
            //width: 295px;
            width:300px;
        }
    
        .retiree-box-wrapper{
            display:flex;
            padding: 1.5rem 0;
            overflow-y: scroll;
            width: calc(100vw - 420px);
            align-items: flex-start;
    
            .retiree-page-box{
                margin: 1em;
                min-width: 640px;
                overflow-x: scroll;
            }
        }

        .beneficiary-slide-nav{
            display: flex;
            align-items: center;
            width: 60px;
        
            button{
                width: 50px;
                height: 100px;
                border-radius: 0;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
        
                &.beneficiary-slide-nav-left{    
                    border-bottom-right-radius: 50px;
                    border-top-right-radius: 50px;
                }
        
                &.beneficiary-slide-nav-right{    
                    border-top-left-radius: 50px;
                    border-bottom-left-radius: 50px;
                }
        
                i{
                    font-size: 1.5rem;
                }
            }
        }
    }
}


section.beneficiary-version{
    .result-info-action-content{
        padding: 1.3em;
        margin-top: 0;
        background: $first-color;

        .info-section{
            p{
                color:#fff;
                text-align: center; 
                span{
                    color:#fff;
                    margin-left:3px;
                }
            }
        }
    }

    .banner-pointer-wrapper{
        margin-bottom: 1em;
    }

    .result-section.result-version-1 {
        margin-top: 0;

        tr{
            border:0;
            margin-bottom: 0;

            &:nth-child(even){
                background:#f9f9f9;
            }
        }
    }
}

div.bmr-error-message{
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding: 5px 20px;
    margin-bottom: 6px;

    span{
        font-weight: 500;
    }

    button.btn{
        font-size: 12px;
        text-decoration: underline;
        padding: 0;
        text-transform: capitalize; 
    }
}

/**********************************************************************************
********************** END NEW BENEFICIARY DASHBOARD DESIGN************************
***********************************************************************************/

.beneficiary-slide-nav {
    button.b-slide-nav{
        padding:5px 25px;
    }
}