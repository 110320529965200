@media (min-width: 1200px){
    div.container,
    section.container {
        max-width: 1170px;
        padding: 0;
    }   
}

@media (max-width: 1199px){
        html {
            body {
                h1{
                    font-size:52px;
                }
        
                h2{
                    font-size:38px;
                }

                p{
                    font-size:18px;
                }

                //overall container
                .container{
                    max-width: 100%;
                }

                .form-left-img{
                    img{
                        max-width:100%;
                    }
                }
            }
        }


    //card list
    div.cardBox-list{

        .cardBox{
            text-align:center;
            padding: 10px;

            .cardBox-content{
    
                .cardBox-title{
                    margin: 10px 0 20px;
                    font-size:18px;
                    height:auto;
                }
    
                .cardBox-desc{
                    height:auto;
                }
            }
        }
    }
    // retiree-dashboard page
    section.retiree-dashboard-section div.container div.retiree-box-wrapper{
        margin: 2em 0 5em 2em;
    }
}

@media (max-width: 1160px){
    //stephen - 11-28-2019
    div.view-wrapper{

        .view-left{
            display:none;
        }

        .view-center{
            max-width: 70%;
        }

        .view-right{
            flex:auto;
        }
    }
}

@media (max-width: 991px){
    //global
    .onDesktop{
        display:none;
    }
    
    .hideOnTablet{
        display:none;
    }
    
    //typography
    html{
        body{
    
        h1{
            font-size:52px;
    
            &.medium{
                font-size:32px;
            }
    
            &.small{
                font-size:27px;
            }
        }
    
        h2{
            font-size:38px;
        }
    
        h3{
            font-size:32px;
        }
    
        h4{
            font-size:25px;
        }
        }
    }

    form .row div.form-group label{
        font-size:20px;
        display: flex;
        align-items: center;
    }

    //banner
    div.header-banner-wrapper{
        h1.banner-main-title{
            span{
                font-size: 0.7em;
            }
        }
    }

    body div.header-b-wrapper {
        margin-top: 70px;
    }
}

@media (min-width:768px){
    body{
        .row,
        .col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,
        .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,
        .col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12{
            margin:0;
            padding:0;
        }
    }
}

@media (max-width: 767px){


    //typography
    html{
        body{
        
            h1{
                font-size:44px;

                &.small{
                    font-size:19px;
                }
            }
        
            h2{
                font-size:30px;
            }
        } 
    }

    [class^="col-12"] {
        margin-bottom: 1rem !important;
    }

    //banner
    .header-banner-wrapper{
        padding-bottom:1em;
        .banner-wrapper{
            .banner-image-wrapper{
                display:none;
            }
        }
    }

    div.footer-top-spacer{
        height:0;
    }

    // retiree dashboard
    section.retiree-dashboard-section{
        > .retiree-dashboard-section-left,
        > .retiree-dashboard-section-right{
            display:none;
        }

        > div.container{
            flex-direction:column;

            .retiree-navigation-wrapper{
                padding:1em;
                max-width:100%;
            }
            
            div.retiree-box-wrapper{
                margin: 1em auto;
            }
        }
    }

    #marketplace-widget-form {
        width: calc(100% - 40px) !important;
        position: fixed;
        top: 50%;
        box-shadow: 0 0 0 0 #000;
        z-index: 15001;
        padding: 2em !important;
        transform: translate(-50%,-50%);
        left: 50%;
        height: max-content;

        .marketplace-support-widget{
            > div:first-child{
                padding: 0;
                margin-bottom: 0 !important;
                max-width: 100%;
                flex: 1;

                .button-wrapper{
                    display: flex;
                    margin: 0 0 10px !important;
                    flex-direction: row !important;
                    justify-content: space-between !important;
                    // padding: 0 3em;

                    button{
                        font-size: 12px !important;
                        margin-bottom: 0;
                    }
                }
            }
    
            > div:last-child{
                display: none !important;
            }
        }
    }
}

@media (max-width: 640px){
   //typography 
    html{
        body{
        
            h1{
                font-size:36px;
            }
        }
    }
    // form .row div.form-group label{
    //     font-size:16px;
    // }

        //stephen - 11-28-2019
        div.view-wrapper{
            .view-center{
                max-width: 100%;
            }
            .view-right{
                display:none;
            }
        }

}

@media (min-width: 576px){ // tablet and higher screen
    .mobileOnly{
        display:none !important;
    }
    .desktopOnly{
        display:block !important;
        &.flex{
            display:flex !important;
        }
    }
}

@media (max-width: 575px){
    .mobileOnly{
        display:block !important;
    }
    .desktopOnly{
        display:none !important;
    }

    div.main-content-wrapper{
        padding:0;
    }

    .container,
    section.container,
    div.container{
        padding:0;
    }

    body{
        .padding-mobile{
            padding:0 20px;
        }
    }

    div.banner-pointer-wrapper, 
    div.banner-pointer-wrapper-secondary, 
    div.banner-pointer-wrapper-tertiary{
        margin-bottom:1em;
    }

    .onTablet{
        display:none;
    }

    .col-12 [class^="col"] {
        padding:0;
    } 

    button.btn.btn-back{
        margin-right:5px;
    }


    div.b24-window-panel{
        width: 95%; 
        transform: translateX(-50%);
        left: 50%;
    }

    div.bx-livechat-wrapper.bx-livechat-show{
        width: calc(100% - 20px);
        right: auto;
        bottom: 10px;
        left: 50%;
        z-index: 1000200 !important;
        transform: translateX(-50%) !important;
    }

    div.custom-widget-chat-btn{
        right: 10px;
        bottom: 10px;
    }

    div div.custom-widget-chat-btn.new-version img.custom-widget-button-icon{
        width: 90px;
    }

    div.custom-widget-chat-btn .custom-widget-chat-btn-inner i.fa{
        right: 1px;
        top: 3px;
    }

    div.header-banner-wrapper-plain h1 {
        padding-bottom: 0;
    }

    #filter_result_btn{
        height:auto !important;

        .fas.fa-filter{
            background: $first-color;
            padding: 15px;
            border-radius: 50%;
            box-shadow: 1px 1px 15px -5px grey;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
        }

        > button.btn.btn-first{
            display:none;
        }
    }

    button.marketplace-widget-btn {
        // height: 60px;
        // width: 60px;
        right: 7px;
        bottom: 10px;
    }

    div.filterResultPopUp{
        padding:1em !important;

        .resultFilterModal{
            padding: 1em;
    
            > div:first-child{
                display: none !important;
            }
    
            > div:last-child{
                max-width: 100% !important;
                flex: 1;
    
                ul{
                    padding-left:0;
                    
                    li{
                        img{
                            max-width: 70px;
                        }
                    }
                }

                > button{
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}

@media (max-width: 480px){
//typography 
    html{
        body{
            *{
                font-size:14px;
            }
        
            h1{
                font-size:26px;
            }

            .box-12{
                padding:0;
            }
        }
    } 

    section#slider-form{
        
        .question-text{
            font-size: 20px;
            display: block;
            font-weight: 500;
            margin-bottom: 2rem;
        }
    }

}

@media (max-width: 320px){
        form .form-group{
            flex-direction: column-reverse;
            align-items: center;

            // button.btn.btn-back{
            //     margin-top:1em;
            // }
        }
}

@media (max-width: 300px){
    .below-300px{
        display:none;
    }

    .company-logo.mobile-navbar-logo{
        max-width:140px !important;
        width:100% !important;
    }

    .header-b-wrapper a img.company-logo {
        max-width: 100% !important;
    }
    .cardBox-img-wrapper{
        width:auto !important;
        height:auto !important;
    }
}

@media (max-width:575px){
    .swal2-container .swal2-popup.swal2-modal.email-my-quote-modal,
    .swal2-container .swal2-popup.swal2-modal{
        padding:2em 1em 1em !important; 
    }

    div.adjusted-select-form-group{
        display: block;
        select{
            margin-top:0;
        }
    }

    h4.form-input-heading{
        line-height:1em;
    }
}

