/* You can add global styles to this file, and also import other style files */

// @import '../node_modules/@clr/ui/src/utils/dependencies.clarity';

// @import '../src/raw-assets/scss/_light-variable-overwrite.theme';

// @import '../node_modules/@clr/ui/src/utils/components.clarity';

// ======================RESET=====================
// body{
// 	.row,
// 	.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,
// 	.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,
// 	.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12{
// 		margin:0;
// 		padding:0;
// 	}
// }
// =====================END RESET==================

@import 'animate.css';
@import '../src/raw-assets/scss/main';
@import url(~fullpage.js/dist/fullpage.min.css);

//font-family
.font-primary{
	font-family:"cera",Arial, "Open-sans";
}

.font-secondary{
	font-family:$font-family-secondary;
}

.font-poppins {
	font-family: 'Poppins', sans-serif !important;
}

.no-border-radius{
	border-radius:0 !important;
}

//font weight:

.fWeight-inherit{
	font-weight:inherit;
}
.fWeight-100{
	font-weight:100;
}


	body .bg-primary{
		background-color:$first-color !important;
	}

	.bg-first{
		background-color:$first-color;
	}

	.bg-third{
		background-color:$second-color !important;
	}

	.bg-dirty-white{
		background-color:$dirty-white-color;
	}


.fWeight-200{
	font-weight:200;
}

.fWeight-300{
	font-weight:300;
}

.fWeight-400{
	font-weight:400;
}

.fWeight-500{
	font-weight:500;
}

.fWeight-600{
	font-weight:600;
}

.fWeight-700{
	font-weight:700;
}

.fWeight-800{
	font-weight:800;
}

.fWeight-900{
	font-weight:900;
}

.fWeight-black{
	font-weight:700;
}

//borders
.bordered-left{
	border-left:1px solid $sixth-color;
}

.bordered-right{
	border-right:1px solid $sixth-color;
}

.bordered-bottom{
	border-bottom:1px solid $sixth-color;
}

.bordered-top{
	border-top:1px solid $sixth-color;
}

.bordered{
	border:1px solid $sixth-color;
}

.mobileOnly{
	display:none;
}

//typography
body{
	*{
		font-size:16px;
	}

	h1{
		font-size:60px;
		font-weight: 700;
		letter-spacing: 1px;
		line-height: 1em;

		&.medium{
			font-size:40px;
		}

		&.small{
			font-size:35px;
		}
		*{
			font-size:1em;
		}
	}

	h2{
		font-size:46px;
		color:$first-color;
		*{
			font-size:1em;
		}
	}

	h3{
		font-size:40px;
		*{
			font-size:1em;
		}
	}

	h4{
		font-size:33px;
		*{
			font-size:1em;
		}
	}

	h5{
		font-size:23px;
		*{
			font-size:1em;
		}
	}

	h6{
		font-size:18px;
		*{
			font-size:1em;
		}
	}

	strong,b,i,span{
		font-size:1em;
	}

	strong,b{
		font-weight:bold !important;
	}

	.row{
		margin-left:0;
		margin-right:0;
	}
}

//banner
.banner-image-wrapper{
	display:flex;
	justify-content: flex-end;
	align-items: flex-end;
}



// components overwrite
html{
	height:100%;
	body {
		height:100%;
		.fColor-faint-grey{
			$color:$faint-grey-color;
		}
		background: $global-app-background;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;

		* {
			@extend .font-primary;
			color: $base-text-color; 
			font-weight:400;
			
		}

		h1,h2,h3,h4,h5,h6,button,a{	
			font-weight:800;
			color:$first-color;
		}

		.height-auto{
			height:auto;
		}
	}
}

.fColor-warning{
	color:$warning-color;
}

.color-primary{
	color:$first-color;
}

.color-secondary{
	color:$second-color;
}

.fColor-base{
	color:$base-text-color;
}

.fColor-faint-grey{
	color:$faint-grey-color !important;
}

.fColor-dirty-white{
	color:$dirty-white-color;
}

.bg-faint-grey{
	background:$faint-grey-color !important;
}



.header-banner-wrapper{
	background-image:url("/assets/img/home/new/bg1.png");
	background-repeat: no-repeat;
	background-size:cover;
	background-position: center;
	color:#fff;
	position:relative;

	h1.banner-main-title{

		span{
			font-size:40px;
			font-weight: 600;
			line-height: 1em;
			color:#fff;
		}
	}

	p.banner-sub-title{
		color: #fff;
		font-size: 18.57px;
		font-weight: 100;
		letter-spacing: 0.6px;
		margin-top: 22px;
	}

	
}

.header-banner-wrapper-plain{
	// @extend .header-b-wrapper;
	background:$first-color;

	h1{
		font-size: 40px;
		font-weight: 600;
		padding: 0 0 60px;
	}

	div#header-b {
		margin-bottom: 2em;
	}
}

.banner-result{
	.banner-wrapper{
		padding-bottom:90px;
		&.loggedIn{
			padding-bottom:0;
		}
	}
}


.banner-wrapper{

	h1{
		color:#fff;
	}
	
	img.banner-main-image{
		max-width: 365px;
		width: 100%;
		margin-top: -30px;
		margin-bottom: -20px;
	}
}

.uppercase{
	text-transform:uppercase;
}

.capitalize{
	text-transform:capitalize;
}

img {
  max-width: 100%;
}


.white-color-bg{
	background-color:#ffffff !important;
}

.full-width{
	max-width:100% !important;
	width:100% !important;
}

.no-width{
	max-width: fit-content !important;
}

.banner-section{
	margin-bottom:40px;
	.row{
		.banner{
			// height: 335px;
			// height:230px;
			padding:20px 0;
			background: url('/../../assets/img/banner-background.jpg');
			text-align: center;
			color: #fff;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			.banner-box{
				max-width: 618px;

				hr{
					background: #fff;
					height: 2px;
					border: none;
					margin-bottom: 35px;
				}

				p{
					font-size: 1.2em;
					color: #ffffff;

					span{
						font-size: 0.8em;
					}
				}

				h1{
					font-size: 30px;
					font-weight: 600;
				}
			&.marketplace-banner{
				max-width:950px;
			}
			}
		}
	}
}

// insurance-marketplace > medicare-advantage

.tt-maqr-box{
	height: auto;
	padding: 20px 0;
	border:1px solid #d2d2d2;
	vertical-align:middle;
	font-size: 20px;
	&.auto-height{	
		height:auto;
		padding:20px;
	}
}

.table{
	.tt-maqr-box{
		@extend .tt-maqr-box;
	}
}


// generic style for all quote input form pages
// .tt-input-form-section{
	// height:676px;   

form{

	h2{
		font-size: 30px;
		font-weight: 600;
		text-align: center;
		margin-bottom: 2em;
	}

	.form-group{
		max-width: 405px;
		width:100%;
		margin-bottom: 30px;
						
		label{
			color:$fifth-color;
			font-size:23px;
			font-weight:500;
		}

		.form-control{
			width: 100%;
			height:42px;
		}

		textarea.form-control{
			height:auto;
		}
		
		.custom-form-info-icon{
			color: #ffffff;
			background: #49586b;
			float: left;
			margin-left: 10px;
			font-size: 13px;
			padding: 6px 11px;
			border-radius: 50%;
			cursor:pointer;
			height: 27px;
		}

		&.ahd-main-wrapper{
			max-width: 280px;
			text-align: right;
		}
				
	}
}

label.label-with-info-icon{
	display:flex;
	align-items:center;
}

i.global-info-icon-first{
	//background: $grey-color;
	background: $second-color;
	color: #ffffff;
    width: 25px;
    height: 25px;
    font-size: 15px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 10px;
    padding: 5px;
    flex: none;
    margin-top: 5px;
}

i.global-info-icon-second{
	@extend i, .global-info-icon-first;
	border-radius: 50%;
	background-color:#ffffff;
	color: #fff;
	margin: 0;
}

div.selector-modal-content{
    text-align:left !important;
	strong,b{
		font-weight:bold;
	}
}

.speakToCounBtn {
    display: block;
    max-width: 130px;
    border-radius: 30px;
    padding: 5px 0;
    color: #ffffff;
    margin:auto;
}

.callUsNowBtn{  
    max-width: 250px;
    border-radius: 10px;
    margin: auto;
    padding: 15px 0px;
    color: #000;
    font-weight: bold;
}


div.plw-inner{
	font-size:14px;
}

.borderRadius-30{
	border-radius:30px !important;
}

.fColor-blue{
	color:$first-color !important;
}

.fColor-orange{
	color:$second-color !important;
}

.fColor-white{
	color:#fff !important;
}

.fColor-black{
	color:#000 !important;
}

.fColor-grey{
	color:#545454 !important;
}

.lHeight-1em{
	line-height:1em !important;
}

//all form labels > sub
label{
	//color:#fff;
	color:#1f285d;
	font-size: 18px;
	font-weight:600;

	sup{
		color: red;
		margin-right: 5px;
		top: 1px;
		font-size: 20px;
	}
}

.modalSlideNavigation{
	button{
		width: 60px;
		padding: 2px;
		font-size: 25px;
		&:nth-of-type(1){
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			margin-right: 1px;
		}
		&:nth-of-type(2){
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			margin-left: 1px;
		}
	}
}

// ALL RESULT PAGE 
.result-wrapper-first{
	button{
		font-size: 14px;
	}
}


.form-group{
	width:100%;
}

div.custom-modal-wrapper{
	font-size:14px;
	text-align:center;
	border-radius:15px;
	padding: 85px 20px 40px;

	ul.rating-stars{
		list-style: none;
		li{
			display:inline-block;
    		margin: 0 2px;
		}
	}
	table{
		width:100%;
	}

	// .btn-second{
	// 	margin: auto;
	// 	font-size: 1.1em;
	// 	padding: 10px;
	// 	color: #000;
	// 	font-weight: 600;
	// }

	.schedTimeBtn{
		border:0;
		margin: auto;
		padding:6px 25px;
		border-radius: 30px;
	}

	.applyOnlineSlideNavigation{
		button{
			width: 60px;
			padding: 2px;
			font-size: 25px;
			display: inline-block;
			border-radius: 0;

			&:nth-child(2){
				border-bottom-left-radius: 30px;
				border-top-left-radius: 30px;
				margin-right: 1px;
			}

			&:last-child{
				border-bottom-right-radius: 30px;
				border-top-right-radius: 30px;
				margin-left: 1px;
			}

		}
	}
}

.swal2-close{
	outline:0 !important;
}

.form-group-info{
	display:flex;
	
	i.global-info-icon-first{
		width:40px; 
	}
}

.top-spacer{
	margin-top:40px;
}

//boxes
.custom-box{
	border-radius:$border-radius;
	box-shadow: $box-shadow;
}

.footer-top-spacer{
	background:#156aff;
	height:6em;
	// margin-top: 50px;
}

body .header-b-wrapper{
	margin-top:45px;
}

// banner-pointer-wrapper
.banner-pointer-wrapper{
	margin:0 auto;
	width:60px;
	position:relative;
	height: 35px;
	margin-bottom:3em;

	.banner-pointer-background{
		width: 0;
		height: 0;
		border-left: 32px solid transparent;
		border-right: 32px solid transparent;
		border-top: 32px solid #156aff;
		position:absolute;
		top:0;
		left:0;
	}

	i{
		position: absolute;
		text-align: center;
		z-index: 1;
		top: -5px;
		color: #fff;
		width: 65px;
	}
	
}

.banner-pointer-wrapper-secondary{
	@extend .banner-pointer-wrapper;
	margin: 0 auto 35px;

	.banner-pointer-background{
		border-top: 32px solid #fff;
	}

	i{
		color: $first-color;
	}
}

.banner-pointer-wrapper-tertiary{
	@extend .banner-pointer-wrapper;

	.banner-pointer-background{
		border-top: 32px solid $global-app-background-secondary;
		margin-top: -10px;
	}
}

.cardBox-list{

	.cardBox{
		text-align:center;
		padding:14px;// padding: 20px;

		.cardBox-img{
			max-width:200px;
		}

		.cardBox-content{

			.cardBox-title{
				font-size: 26px;
				color: #156aff;
				font-weight: 600;
				margin: 10px 0 40px;
				text-transform:capitalize;
				height:50px;
				line-height: 1em;
				display: flex;
				align-items: flex-end;
    			justify-content: center;
			}

			.cardBox-desc{
				font-size: 18px;
				letter-spacing: 0.1px;
				line-height: 1.5em;
				height:235px;
			}
		}
	}
}


.form-left-img{
	display:flex;
	justify-content:flex-start;
	align-items:center;
	
	img{
		max-width: none;
    	margin-left: -10em;
	}
}


.form-input-heading{
	text-align:center;
	font-weight:600;
	margin-bottom: 2em;
	line-height: 2.8rem;
}

span.helloTapTapTeamSpan{
	font-weight: inherit;
	color: inherit;
	width: 100%;
	display: block;
	margin-bottom: 30px;
}

.modal-title-heading{
	text-align:center;
	width:100%;
	margin-bottom:1.5em;
}

.fluid{
	width:100%;
}

.mat-stepper-label-position-bottom .mat-step-header div.mat-step-icon-selected{
	background: $button-gradient;
	border:0;
	color:#fff;

	.mat-step-icon-content{
		span{
			color:#fff;
		}
	}
 }

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon{
	width: 60px;
    height: 60px;
    background-color: #e6e6e6;
    border: 2px solid #c1c1c1;
}

.mat-step-icon-content{
	span{
		color:$first-color;
		font-size: 23px;
    	font-weight: 500;
	}
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label{
	text-transform: uppercase;
}

mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, 
mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after{
	top:55px;
	width: calc(50% - 30px);
	border-top-width: 2px;
}

mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-stepper-horizontal-line{
	top:55px;
	border-top-width: 2px;
}
.mat-step-icon-state-edit.mat-step-icon.mat-step-icon-state-edit{
	background:$first-color;
}

.mat-step-icon-state-edit.mat-step-icon .mat-icon{
	font-size:20px;
	width:20px;
	height:20px;
	font-weight:bold;
}

.hidden{
	display:none;
}

.flex-auto{
	flex:auto !important;
}

.flex-none{
	flex:none !important;
}

//global table result UI
.result-list-table-wrapper{
	margin-top:85px; 

	tr{
		border-top:1px solid $fifth-color;
		margin-bottom:1em;

		th{
			text-transform:uppercase;
			color:$fifth-color;
			font-size:16px;
			font-weight:500;
			letter-spacing:0.5px;
			padding: 5px 0;

			&:first-child{
				text-align:left;
			}

			&:nth-child(4){
				 padding-left:40px;
			}

			&:last-child{
				text-align:center;
		   }
		}

		td{
			padding: 2em 0;

			// padding-left: 40px;
			
			&:first-child{
				padding-left:0;
				width: 370px;

				.result-logo{
					max-width:330px;
				}
			}

			&:nth-child(2){
				width: 270px;

				.company-info-content{
					max-width:227px;
					width:100%;

					button{
						padding: 5px 10px 3px 0;
						width: 200px;
					}
				}
			}

			&:nth-child(3){
				
				width: 125px;

				.rating-content{
					font-size:24px;
					font-weight:600;
					width: 70px;
					text-align: center;
				}
			}

			&:nth-child(4){
				
				width: 205px;

				.pricing-content{
					max-width:157px;
					// margin: 0 auto;

					span{
						font-size:24px;
						margin-bottom:5px;
						font-weight:600;
					}

					select{
						height: 52px;
						padding: 10px 25px;
					}
				}
			}

			&:last-child{

				width: 220px;

				.action-content{
					max-width: 205px;
				}
			}
		}
		&:first-child{
			border:0;
		}
	}

	button{
		max-width:100%;
	}
}

//stephen - 11-28-2019
.view-wrapper{
	max-width: 1350px;
	width:100%;
	margin:0 auto 3em;

	.view-left{
		// background: pink;
		flex:1;
	}

	.view-center{
		max-width: 600px;
		width:100%;
		
		.view-center-content{
			padding: 0 15px;
			margin:auto;
		}
	}

	.view-right{
		flex:1;
		text-align:center;
	}
}

.visibility-hidden{
	visibility:hidden;
}

.main-content-wrapper{
	padding:0 20px;
	// &.loggedIn{
	// 	margin-top: 95px;
	// }
}

.result-value-strong{
	font-size:28px;
}

.result-value-strong-medium{
	font-size:22px;
}

.result-value-strong-small{
	font-size:16px;
}

// =====================RETIREE DASHBOARD=================
section.retiree-dashboard-section{
	background:#f1f1f1;
	display:flex;

	div.retiree-dashboard-section-left{
		background:#fff;
		flex:auto;
		padding-left:2em;
	}
	div.retiree-dashboard-section-right{
		background:#f1f1f1;
		flex:auto;
		padding-right:2em;
	}
	div.container{
		display:flex;
		justify-content:space-between;
		padding:0;

		.retiree-box-wrapper{
			// background:#fff;
			background: none !important;
			border: none !important;
			// box-shadow: 0 0 25px -8px #ccc;
			border-radius:15px;
			// padding:3em;
			flex: auto;
			margin: 2em 0 5em;
			// height: 1420px;
			max-height: 1420px;
    		overflow: scroll;

			.retiree-form-title{
				text-transform:uppercase;
				color:#666;
				font-size:18px;
				margin: 0 0 1.5em;
			}
	
			span.retiree-form-group-header{
				background:#f1f1f1;
				color:$first-color;
				padding:5px 10px;
				display:block;
				width:100%;
				font-weight: 500;
				margin: 1em 0;
				font-size: 15px;
			}

			.form-group{
				max-width:100%;
				
				label{
					font-size:15px;
				}

				button.btn.btn-first{
					font-size:15px;
					height:55px;
				}
			}
		}
	}
}

.retiree-navigation-wrapper{
    background: #fff;
    box-shadow: 30px 0 25px -35px #ccc;
    padding: 2em 0;
	height: 100%;
	margin-right: 2em;
}

.retiree-navigation-wrapper-box{
    width:260px;//width: 330px;//width:280px;
    
}

.swal2-container{
    z-index:999 !important; 
}

body .result-version-1 .pricing-content .resizing_select {
	width: 90px;
} 
	
body .result-version-1 .pricing-content .width_tmp_select{
	display : none;
} 

.no-bg{
	background:none !important;
}

.no-border{
	border:none !important;
	box-shadow:none !important;
}


@media print {
  .noprint {
     visibility: hidden;
  }

  .application-box{
	  padding:0 !important;
  }

  .medicare-advantage-result-wrapper {
	margin-bottom: 4em !important;
	page-break-after: avoid;
  }

  header.retiree-dashboard-header{
	  margin-top:0 !important;
  }

  .tagove-livechat-widget{
	  display:none !important;
  }

  html{ height: 99% !important; }

  html, body {
	height:100vh !important; 
  }
}

.app-main-content{
	min-height:100%;

	&.no-min-height{
		min-height:0;
	}
}

app-loading{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
	z-index: 1;
	
	&.loading-fixed{
		position: fixed;
		height: 100vh;
	}
}

.d-flex-1{
	flex:1;
}

// ------------------------------------------------------------------------
//---------------------------SCROLLBAR DESIGN------------------------------
//-------------------------------------------------------------------------
/* The emerging W3C standard
   that is currently Firefox-only */
   * {
    scrollbar-width: thin;
    scrollbar-color: #ababab transparent;
  }
  
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
	width: 5px;
	height: 5px;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 20px;
  }

@page {
	margin-top:2em !important;
}

.olark-launch-button.olark-text-button .olark-button-text span {
    color: #fff;
}


.adjusted-select-form-group{
    position: relative;
    display: flex;
	height: 75px;
	
	label{
		display:block !important;
	}

    select{
        position: absolute;
        margin-top: 40px;
        z-index: 1;
	}
	
	&.select-form-normal{
		flex-direction:column;
		height:auto;
		select{
			position:unset;
			margin-top:0;
		}
	}
}

.mapdFilterCollapse-btn{
	border: 0;
    background: none;
    color: #000;
    font-weight: 500;
	margin-bottom: 5px;
	
	&:focus{
		outline:none;
	}
}

.mat-tooltip.toolTipFont{
	font-size:14px !important;
	text-align:center;
}

.b24-widget-button-social a.b24-widget-button-social-item {
    width: 66px;
	height: 66px;
	border-radius:50%;
}

div.b24-widget-button-block{
	width: 80px;
    height: 80px;
}

div.b24-widget-button-inner-block{
	height: 80px;
}


// form validation
.form-validated .form-control.ng-invalid, 
.form-validated .custom-select.ng-invalid,
.form-control.ng-invalid.ng-touched.ng-dirty, 
.custom-select.ng-invalid.ng-touched.ng-dirty,
.ng-dirty.ng-invalid,
button.btn.ng-invalid,
a.btn.ng-invalid,
.ng-invalid.error,
button.btn.error,
a.btn.error{
	border-color: #dc3545;
	color:#dc3545;
}

.app-main-content.flat-bg-content{
	background:$first-color;
	align-items: center;
	justify-content: center;
	display:flex;

	> div{
		width:100%;
	}
}


app-slider-form{
	display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
}

ul.slider-form-selection-box {
	display: flex;
    padding-left: 0;
    min-width: 100%;
	li{
		list-style-type: none;
		width: 33%;
		display: flex;
		padding: 20px;
		align-items: center;
		justify-content: center;
		
			a{
				color: #000;
				text-transform: uppercase;
				background: #f1f1f1;
				width: 100%;
				text-align: center;
				padding: 20px;
				box-shadow: 0px 3px 25px -9px #343434;
			}
	}
}

// ========================START TAPTAP COUNSELOR TEAM BOTTOM RIGHT =======================
div.b24-widget-button-position-bottom-right .b24-widget-button-popup{
	width: 400px;
    left: -408px;
}

.b24-widget-button-position-bottom-right .b24-widget-button-popup-image{
	width: 80px;

	.b24-widget-button-popup-image-item{
		width: 65px;
		height: 65px;
	}
}

.b24-widget-button-position-bottom-right .b24-widget-button-popup-content{
	width: 264px;

	.b24-widget-button-popup-name{
		font-size: 19px;
	}

	.b24-widget-button-popup-description{
		font-size: 16px;
	}
}

// =====================END START TAPTAP COUNSELOR TEAM BOTTOM RIGHT =======================

.coverage-card{
	margin:auto;

	>div:first-child{
		img{
			max-width: 185px;
			width: 100%;
		}
	}

	>div:last-child{
		img{
			max-width: 300px;
			width:100%;
		}
	}
	
	p {
		font-size: 20px;
	}
}


div.speak-to-counselor-popup-box{
	// background:$bg-gradient;
	
	.speak-to-counselor-popup-box-close-btn{
		color:$base-color;
	}
}

div.mo-box{
    display:flex;
    justify-content:center;
    flex-direction:column;   
    align-items: center;
    margin-bottom:3em;
    
    h6{
        color:$base-text-color;
        text-transform:uppercase;
        text-align:center;
        margin-top:1em;
        max-width: 230px;
        cursor:pointer;
    }
}

div.mo-box-icon{
	max-width:150px;
	width:100%;
	height:150px;
	display:flex;
	align-items:center;
	justify-content: center;
	padding: 1.5em;
	margin:auto;
	background: #c5c4c4; // #e5e8ec;
	border-radius:15px;
	cursor:pointer;

	img{
		max-height:100%;
	}
}


#slider-form{
	.question-text{
		font-size:30px;
		color:#fff;
		text-align: center;
	}
}


.slider-form-body{
	div.b24-widget-button-wrapper{
		display:none;
	}
}

app-loading.bg-transparent{
	background:transparent !important;
}

#slider-form input[type=text]{
	max-width: 450px;
    width: 100%;
    margin: auto;
}

.swal2-popup.speak-to-counselor-popup-box{
	border-radius:20px;
}


div.bx-livechat-body{
	//background-image:url('./assets/img/home/new/bg2-widget.png');
	background-size: cover;
    background-repeat: no-repeat;
	background:$first-color;
}

.bx-livechat-help-container * {
    color: #fff !important;
    opacity: 1 !important;
}

div.beneficiary-dashboard-popup{
    border-top:6px solid $first-color;
}

.bx-im-message{
	&.bx-im-message-type-system{
		span.bx-im-message-content-text{
			color:#fff;

			b,strong{
				color:#fff;	
			}
		}

		.bx-im-message-content-date{
			color:#fff;
		}
	}
}

.bx-imopenlines-message{
	.bx-imopenlines-message-dialog-number{
		color:#fff;
	}
}

.custom-widget-chat-btn{
	background: #156aff;
    position: fixed;
    right: 25px;
    bottom: 25px;
	padding: 20px 10px;
    border-radius: 15px;
    z-index: 9;
	border: 2px solid yellow;
	cursor:pointer;

	.custom-widget-chat-btn-inner{
		position:relative;
		width:100%;
		height:100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		
		i.fa-comment-dots{
			color:yellow;
			font-size: 50px;
		}	

		i.fa{
			// color: #fff;
			// position: absolute;
			// right: 0px;
			// top: -11px;
			// font-size: 15px;
			color: #0a0a0a;
			position: absolute;
			right: 15px;
    		top: 25px;
			font-size: 15px;
			background: gold;
			border-radius: 50%;
			width: 23px;
			height: 23px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	
		span{
			color:#fff;
			font-weight: 500;
		}
	}

}

div.custom-widget-chat-btn.new-version{
	background:transparent;
	border:0;
	padding:0;

	img.custom-widget-button-icon{
		width: 165px;
		max-width:100%;
	}

	.custom-widget-chat-btn-inner{
		//box-shadow: 0px 0px 30px -10px grey;
		padding: 0;
		border-radius: 50%;
		//background:#fff;
	}
}

.custom-widget-chat-btn-small{		
	display: none;
	align-items: center;
	flex-direction: column;
	position: fixed;
	right: 25px;
	bottom: 25px;
	padding: 10px;
	z-index: 9;
	cursor:pointer;

	i{
		color:red;
		font-size: 70px;
	}
}

img.custom-widget-button-icon{
	max-width: 100px;	
}


.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible{
	display:none !important;
}

#custom-widget-chat-btn-id,
#custom-widget-chat-btn-id-after{
	display:none;
}


// widget design
.b24-form-wrapper .b24-form-header-title{
	color:$first-color;
	font-weight: 600;
    font-size: 18px;
	letter-spacing: 0;
	margin-bottom: 0;
}

div.b24-form-state-container .b24-form-success{
	background-color: $first-color;
}

div.b24-form-state-container div.b24-form-state-text p{
	color:#fff;
}

.b24-form-field.b24-form-field-radio.b24-form-control-radio{
	border:0;
	background:transparent;
}

.b24-form-wrapper label.b24-form-control{
	flex-direction: row-reverse;
    width: 100%;
	background: none;
	border: 0;
}

.b24-form-wrapper label.b24-form-control input[type=radio]{
	width: 17px;
    height: 17px
}

.b24-form-wrapper span.b24-form-control-label{
	display:none;
}

.b24-form-wrapper label.b24-form-control.b24-form-control-checked{
	background-color: transparent;

	.b24-form-control-desc{
		color:#000;
	}
}


.b24-form-wrapper .b24-form-control span.b24-form-control-desc{
	color: #848484;
	flex: auto;
	font-weight: 500;
    font-size: 16px;
}

.b24-form-wrapper .b24-form-header{
	border:0;
	display: flex;
    align-items: center;
    justify-content: space-between;
	padding: 10px 10px 10px 30px;
	box-shadow: 0 10px 20px -15px $base-color;
}

.b24-form-wrapper .b24-form-content{
	padding-top: 32px;
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.b24-form-wrapper div.b24-form-control-container input{
	border: 0;
    background: #f4f4f4;
}

.b24-form-wrapper .b24-form-btn-block .b24-form-btn{
	text-transform: uppercase;
    border-radius: 40px;
    padding: 5px 30px;
    box-shadow: -3px 4px 24px -16px #000;
    font-weight: 800;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    border: 0;
    max-width: 200px;
	margin: auto;
	
	max-width: 180px;
	margin: auto;
	outline:none !important;
	
	&#customNextBtnID,
	&#customStartBtnID{
		background: linear-gradient(90deg, #ffa624 50%, #fae453 100%);
		color: #fff;
		border:0;
	}
}

// test wb using jquery
#wb{
	height:100px;
	width:100px;
	background:green;
	position:absolute;
	top:0;
	left:0;
}


/*********************************************************************************************
**************************************COMMON STYLES*******************************************
**********************************************************************************************/

//star ratings
.star-rating-wrapper{
    display:flex;
    justify-content: center;
    align-items:center;

    .star-rating-icons{
	  margin-right:2px;

      i{
          margin-right:2px;
          color:$second-color;
      }
	}

	.star-rating-count{
		color:$second-color !important;
		position: relative;
		width: 23px;
		margin-left: 3px;
		margin-top: -2px !important;

		&:before{
			content:'(';
			position: absolute;
			left: 0;
			top: 1px;
		}
		&:after{
			content: ')';
			position: absolute;
			right: 0;
			top: 1px;
		}
	}

	.star-rating-plan-new{
		color:$second-color !important;
		font-weight:500;
		margin-top: 0 !important;
	}
  }

/*********************************************************************************************
***********************************END COMMON STYLES******************************************
**********************************************************************************************/

div.b24-window-panel{
    height: 520px;
    top: auto;
    right: 10px;
	bottom: 10px;
	border-radius: 10px;

	* {
		font-family: 'cera' !important;
	}

	.b24-form-progress-bar{
		stroke: $first-color;
	}

	.b24-form-progress-bar-title{
		font-size: 20px;
		padding-left: 0;
	}

	.b24-form-progress-bar-container{
		width:44px;
		margin: 15px auto;
	}
}

div.b24-form-wrapper{
	border: 0 !important;

	.b24-form-field-radio .b24-form-control-container{
		margin-bottom:0;

		.b24-form-control{
			min-height:35px;
			border-bottom: 2px solid #f1f1f1;
			padding: 10px 0;
		}

		> .b24-form-control:last-of-type{
			border-bottom:0;
		}
	}

	.b24-form-control-container{
		margin-bottom: 50px;
	}

	.b24-form-field{
		input[type=string],input[type=number]{
			border:1px solid #ccc !important;
			border-radius:5px !important;
			background:#f1f1f1 !important;
		}

		input[type=number]{
			padding-top:0;
		}
	}
}

i.custom-close-div-x-icon{
	font-family: 'FontAwesome' !important;
	color:$base-color;
	font-size: 20px;
	margin-right: 10px;
	cursor:pointer;
}

button.b24-window-close{
	display:none;
}

div.b24-window-scroll-arrow-up-box,
div.b24-window-scroll-arrow-down-box{
	display:none;
}

div.b24-form-btn-container{
	max-width: 380px;
	margin: auto;
	margin: auto auto 15px;
	
	> .b24-form-btn-block:first-child{
		button{
			background: transparent;
			box-shadow: none;
			border: 1px solid $base-color;
			color: $base-color;
		}
	}
}

section.pbc-bottom .mat-horizontal-content-container{
	padding-bottom: 0;
}

ul li {
    margin-bottom: .7em;
}

.swal2-container{
	.swal2-popup.swal2-modal{
		width: 845px;
		padding: 40px 70px;
		border-radius:20px;
		
		#swal2-content{
			text-align:left;
		}
		
		&.email-my-quote-modal{
			padding-left: 0;
    		padding-bottom: 0;
		}
	}
}


app-educationcenter .searchBoxModalWrapper .ng2-auto-complete-wrapper{
	width:80%;
}


div.mat-list{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            div.mat-list-item{
                width: 33%;
                padding: 15px 25px;
                margin-bottom: 1em;

                .mat-list-item-content{
                    align-items: center;
                    display: flex;
                    box-shadow: 0px 10px 30px 20px #77777726;
                    flex-direction: column;
                    text-align: center;
                    padding: 40px;
                    border-radius: 15px;

                    h5{
                        height: 50px; 
                        font-weight: 600;
                        margin-top: -15px;
                        margin-bottom: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    button{
                        width:100%;
                        font-size: 14px;

                        &:first-of-type{
                            margin-bottom: 10px;
                        }

                        &:hover{
                            background:$button-gradient;
                        }
                    }
                }
            }
}

.additional-documents-modal{
	> div:not(:last-child){
		a:hover{
			color:$base-color;
			text-decoration: none;
			display: block;
			cursor: pointer;

			img{
				filter: brightness(0.9) invert(.7) sepia(.5) hue-rotate(100deg) saturate(100%);
			}
	
			p{
				color:$base-color !important;
			}
		}
	}
}

div.swal2-popup.speak-to-counselor-popup-box{
	// width: auto !important;
	// max-width: 850px;
}

@media(min-width:521px){
	div.b24-form-dropdown{
		position: absolute;
		top: 30px;
		width: 100%;
	}
}

.fColor-inherit{
	color:inherit !important;
}

.fWeight-inherit{
	font-weight:inherit !important;
}

.fSize-inherit{
	font-size:inherit !important;
}

.plan-profile-wrapper{
	mat-horizontal-stepper{
		.mat-horizontal-content-container{
			overflow:visible;
			padding:0 70px;
		}

		.mat-horizontal-stepper-header-container{
			padding:0 70px;
		}

		.mat-horizontal-stepper-header{
			padding:15px;
			.mat-step-label{
				overflow:visible;
				white-space: break-spaces;
			}
		}
	}
}

.universal-compare-plans {
	mat-horizontal-stepper{
		.mat-horizontal-stepper-header-container{
			padding:0 70px;
			margin-top:4em;
		}
	}
}


body{
	.benny-content{
		.row,
		.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,
		.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,
		.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12{
			margin:0;
			padding-right:15px;
			padding-left:15px;
		}

		.benny-content-banner{
			margin-bottom: 1rem;
		}

		.benny-content-main{
			padding-left:15px;
			padding-right:15px;
		}

		.fs-2{
			font-size:2em;
		}
	}	
}

#my-base-info-wrapper, #my-coverage-preferences-wrapper{
	.mat-stepper-horizontal{
		background:transparent;

		.mat-step-label{
			text-overflow: unset;
			overflow: unset;
			white-space: break-spaces;
			font-weight: 600;
			color:$base-color;
		}
	}
}

#my-coverage-preferences-wrapper {
	.mat-horizontal-content-container {
		padding: unset;
		background-color: #fff;
	}
	.mat-horizontal-stepper-content {
		display: flex;
		align-items: center;
		justify-content: center;

		.light-header {
			font-weight: 600;
		}
		.details {
			font-size: 24px;
			font-weight: 400;
		}
		.character {
			max-width: 200px;
		}
	}
}

.max-w-fit{
	max-width:fit-content;
}

.cursor-pointer{
	cursor:pointer;
}

.divider{
	border-top:1px solid; 

	 &.horizontal{
		border-top:1px solid; 
	 }

	 &.vertical{
		border-left:1px solid; 
	 }

	 &.dashed{
		border-style:dashed;
	 }
	
	 &.dotted{
	   border-style:dotted;
	 }

	 &.w-2{
		 border-width:2px;
	 }

	 &.w-3{
		border-width:3px;
	 }

	 &.w-4{
		border-width:4px;
	 }
}


.opacity-0{
	opacity:0;
}

.opacity-1{
	opacity: 0.2;
}

.opacity-2{
	opacity: 0.4;
}

.opacity-3{
	opacity: 0.6;
}

.opacity-4{
	opacity: 0.8;
}

.opacity-5{
	opacity: 1;
}

.h-0{
	height:0;
}

 .mat-option:hover {
    background: $first-color !important;
    span,label{
      color:#fff;
    }
  }

  mat-form-field.mat-select-blurred{
	.mat-select-arrow {
		border:none;
	}

	.mat-form-field-underline{
		display:none;
	}

	.mat-form-field-flex{
		padding:10px;
	}

	.mat-form-field-infix{
		border-top: 0;
    	padding: 5px;
	}
  }

  .vdpPeriodControls div.vdpPeriodControl > i:last-of-type{
	display:none;
  }

  .customAutoSuggestSearchField{
	max-height: calc(100vh - 50vh) !important;
	padding: 3px 0 8px;

	  h6.heading{
		  display:block;
		  width:100%;
		  color:#000;
		  margin-bottom:5px;
		  font-size: 15px;
		  padding: 5px 10px;
		  text-transform:uppercase;
	  }
      mat-option.mat-option{
          line-height:1em !important;
		  width:100%;
		  padding: 5px 10px;
          height: auto;

		  .content{
			  display:flex;
			  align-items:flex-start;
			  flex-direction:column;

			.title{
				color:#156aff;
				font-weight:600;
				display:block !important; 
				font-size: 14px;
				margin-bottom: 0;
			}
			.subtitle{
				color:grey;
				font-weight:400;
				font-style: italic;
			}
		  }
		  &:hover * {
			color: #fff !important;
		  }		  
      }
  }

//   HEADER ALIGNMENT
@media(min-width: 1200px) {
    .header-banner-wrapper {
        height: 450px;
        position: relative;

        .banner-wrapper {
            position: absolute;
            margin-right: auto;
            margin-left: auto;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
	
	.force-height-auto {
		height: auto !important;
	}

	.unset-absolute-position {
		position: unset !important;
	}
}

.force-white-color {
	color: #fff !important;
	a {
		text-decoration: none;
		color: #fff !important;
	}
}

.mat-divider-dotted{
	border-top-width: 2px;
	border-top-style: dashed;
	margin: 1.5rem 0;
	border-color: #9d9d9d;
}

// .b24-form-field.b24-form-control-list .b24-form-control-label{
//     text-overflow: unset;
//     overflow: visible;
//     white-space: normal;
//     padding-left: 0;
//     opacity: 1;
//     font-size: 20px;
//     font-weight: 600;
//     line-height: 1.1em;
//     transform: translateY(-50%) !important;
//     top: 50%;
// }

// nov 14, 2022 - widget live chat styling
.b24-form-control-list{
	.b24-form-control-label{
		display:none;
	}
	.b24-form-control{
		padding-top: 0 !important;
    	border: 1px solid #ccc !important;
	}
}

.b24-form-state.b24-form-success{
	background-image:url('./assets/img/widget-live-chat-thank-you-bg.png');
	background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

	.b24-form-state-icon.b24-form-success-icon{
		display:none;
	}

	.b24-form-state-text{
		display:none;
	}
}

