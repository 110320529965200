input,select,textarea{
    &.form-control{
        &.v2{
            background-color:#f2f2f2;
            border:0;
        }

        &.blurred{
            background-color: #f4f4f4;
            border: 0;
            min-height: 45px;
        }
    }
}
